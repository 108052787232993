import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { OperationSummaryService } from "app/components/v2/OperationSummary/operation-summary.service";
import { calculateCompoundInterest } from "app/utils/compound-interest";
import { differenceInDays, format, formatDistanceToNow, isPast, isSameDay, isSameMonth, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import { CreateOperationService } from "../../create-operation.service";
import {
    UnoperatedPayable,
    UnoperatedPayablesResponse,
    UnoperatedPayablesSelection,
} from "./interfaces/unoperated-payables";
import { calculateDiferenceDays } from "app/utils/calculate-diff-days";

@Component({
    templateUrl: "./repurchase-payables.component.html",
    selector: "RepurchasePayables",
    styleUrls: ["./repurchase-payables.component.scss"],
})
export class RepurchasePayablesComponent implements OnInit {
    @Input() assignorId: number;
    @Input() selectionUnoperatedPayables: UnoperatedPayablesSelection;

    @Output() selectEmitter = new EventEmitter<UnoperatedPayablesSelection>();
    @Output() closeEmitter = new EventEmitter<void>();
    @Output() totalRepurchaseEmitter = new EventEmitter<number>();

    public allUnoperatedPayablesResponse: UnoperatedPayablesResponse;
    public allUnoperatedPayables: UnoperatedPayable[] = [];
    public unsubscribeAll = new Subject();
    public unoperatedPayablesTableColumns: string[] = [
        "check",
        "payableNumber",
        "payerName",
        "value",
        "dueDate",
        "timeUntilDueDate",
        "payableType",
        "lateFee",
        "fine",
    ];
    public repurchaseFeesForm: FormGroup;
    public selectedPayables: UnoperatedPayable[] = [];
    public page = 1;
    public totalPages = 0;
    public feeFineSum = 0;
    public payablesSum = 0;
    public isLoading: boolean;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly snack: MatSnackBar,
        private readonly createOperationService: CreateOperationService,
        private readonly operationSummaryService: OperationSummaryService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.repurchaseFeesForm = this.formBuilder.group({
            lateFee: [null, [Validators.required, Validators.min(0.00), Validators.max(100)]],
            fine: [null, [Validators.required, Validators.min(0.00), Validators.max(100)]],
        });
        this.repurchaseFeesForm.valueChanges.pipe(debounceTime(500), takeUntil(this.unsubscribeAll)).subscribe(() => {
            this.doTheMath();
        });
        this.isLoading = true;
        this.allUnoperatedPayablesResponse = await this.createOperationService.getUnoperatedPayables(this.assignorId, {
            page: 1,
            take: 10,
        });
        this.allUnoperatedPayables = this.allUnoperatedPayablesResponse.data;
        this.totalPages = Math.ceil(this.allUnoperatedPayablesResponse.meta.total / 10) || 1;

        // Caso feche e abra o componente
        this.selectedPayables = this.selectionUnoperatedPayables.payables || [];
        this.repurchaseFeesForm.controls.lateFee.setValue(
            this.selectionUnoperatedPayables.lateFeePercentage * 100 || "",
        );
        this.repurchaseFeesForm.controls.fine.setValue(this.selectionUnoperatedPayables.finePercentage * 100 || "");
        this.doTheMath();

        this.isLoading = false;
    }

    formatTimeUntilDueDate(date: string) {
        const dataIso = parseISO(date.slice(0, -1));
        if (isPast(dataIso)) return `Vencido há ${formatDistanceToNow(dataIso, { locale: ptBR })}`;
        return `Faltam ${formatDistanceToNow(dataIso, { locale: ptBR })}`;
    }

    trackByFn(index: number, item: { id: number | null }): number {
        return item.id || index;
    }

    doTheMath() {
        this.calculateTotalRepurchase();
        this.calculateTotalFineFee();
    }

    calculateTotalRepurchase() {
        const payablesSum = this.selectedPayables.reduce((c, next) => {
            return c + Number(next.value) + this.calculateFine(next) + this.calculateLateFee(next);
        }, 0);

        this.payablesSum = payablesSum;
        this.totalRepurchaseEmitter.emit(this.payablesSum);
    }

    calculateTotalFineFee() {
        const totalFine = this.selectedPayables.reduce((total, payable) => total + this.calculateFine(payable), 0);
        const totalLateFee = this.selectedPayables.reduce(
            (total, payable) => total + this.calculateLateFee(payable),
            0,
        );
        this.feeFineSum = totalFine + totalLateFee;
    }

    selectPayable(payable: UnoperatedPayable) {
        const index = this.selectedPayables.findIndex((p) => p.id === payable.id);
        const type = index === -1 ? "push" : "splice";
        const method = {
            push: () => this.selectedPayables.push(payable),
            splice: () => this.selectedPayables.splice(index, 1),
        };
        if (!method[type]) return;

        method[type]();

        if (this.checkDateIsPast(payable.dueDate)) {
            return;
        }

        this.doTheMath();
    }

    calculateFine(payable: UnoperatedPayable) {
        if (this.checkDateIsPast(payable.dueDate)) {
            return 0;
        }

        return (payable.value * this.repurchaseFeesForm.controls.fine.value) / 100;
    }

    checkMonthOrDay(payable: UnoperatedPayable) {
        const dueDate = parseISO(payable.dueDate.slice(0, -1));
        return isSameMonth(new Date(), dueDate) || isSameDay(new Date(), dueDate);
    }

    calculateLateFee(payable: UnoperatedPayable) {
        if (this.checkDateIsPast(payable.dueDate)) return 0;
        const lateFee = this.repurchaseFeesForm.controls.lateFee.value / 100;
        const daysOverdue = calculateDiferenceDays(payable.dueDate).days;
        return calculateCompoundInterest(payable.value, lateFee, daysOverdue);
    }

    close() {
        this.closeEmitter.emit();
    }

    select() {
        if (!this.selectedPayables.length) {
            return this.informError("Selecione ao menos um recebível para recompra");
        }

        if (!this.repurchaseFeesForm.valid) {
            return this.informError("É necessário informar o valor de Mora e Multa");
        }

        this.doTheMath();

        const { fine, lateFee } = this.repurchaseFeesForm.controls;

        this.selectEmitter.emit({
            finePercentage: fine.value / 100,
            lateFeePercentage: lateFee.value / 100,
            payables: this.selectedPayables,
            lateFeeAndFineSum: this.feeFineSum,
            repurchaseValue: this.payablesSum,
        });

        this.operationSummaryService.updateTotalRepurchase(this.payablesSum + this.feeFineSum);

        this.closeEmitter.emit();
    }

    informError(message: string) {
        this.snack.open(message, "x", {
            duration: 3000,
        });
    }

    nextPage() {
        if (this.page + 1 > this.totalPages) return;
        this.loadMoreEmitter(this.page + 1);
        this.page = this.page + 1;
    }

    previousPage() {
        if (this.page === 1) return;
        this.loadMoreEmitter(this.page - 1);
        this.page = this.page - 1;
    }

    firstPage() {
        if (this.page === 1) return;
        this.loadMoreEmitter(1);
        this.page = 1;
    }

    lastPage() {
        if (this.page === this.totalPages) return;
        this.loadMoreEmitter(this.totalPages);
        this.page = this.totalPages;
    }

    loadMoreEmitter(page: number) {
        this.allUnoperatedPayablesResponse.data = [];

        this.createOperationService
            .getUnoperatedPayables(this.assignorId, {
                page,
                take: 10,
            })
            .then((res: UnoperatedPayablesResponse) => {
                this.allUnoperatedPayablesResponse = res;
                this.allUnoperatedPayables = res.data;
            });
    }

    checkIfSelected(payable: UnoperatedPayable) {
        return this.selectedPayables.some((p) => p.id === payable.id);
    }

    formatDateIso(date: string) {
        const newDate = new Date(date.slice(0, -1));
        return format(newDate, "dd/MM/yyyy");
    }

    checkDateIsPast(date: string) {
        return new Date(date).getTime() > new Date().getTime();
    }
}
