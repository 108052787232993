export const BANK_SERVICE_TYPES = [
    {
        label: "Emissão de boletos",
        value: "invoices",
    },
    {
        label: "Custódia de cheque",
        value: "checkCustody",
    },
];

export const PAYABLE_TYPE_LIST = [
    {
        label: "Duplicata",
        value: "DUPLICATA",
    },
    {
        label: "Cheque",
        value: "CHEQUE",
    },
    {
        label: "Nota promissória",
        value: "NOTA_PROMISSORIA",
    },
];
