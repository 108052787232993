/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ApiService } from "app/core/api/api.service";
import { UserService } from "app/core/user/user.service";
import { Cedente } from "app/modules/admin/v1/cedente/interfaces/cedente.types";
import { Payable } from "app/modules/admin/v1/operacoes/operacoes.types";
import { NgxDropzoneChangeEvent } from "ngx-dropzone";
import { BehaviorSubject, Subject } from "rxjs";
import { EnvioNotasModalService } from "./envio-notas-modal.service";
import moment from "moment";
import { CreateOperationService } from "app/modules/admin/v2/operation/create/create-operation.service";
import { FileType } from "app/modules/admin/v2/operation/interfaces";
import { ICreatedPayablesXML } from "./interfaces/created-payables-response.interface";
import { IOutputPayableError } from "app/modules/admin/v2/operation/interfaces/output-payable-error.interface";
import { takeUntil } from "rxjs/operators";
import { ErrorMessage, SuccessMessage } from "app/shared/constants/messages.constant";
import { MAX_FILE_SIZE } from "app/utils/constants/file-size.constant";
import { environment } from "environments/environment";

@Component({
    selector: "app-enviar-notas-modal",
    templateUrl: "./envio-notas-modal.component.html",
})
export class EnviarNotasModalComponent implements OnInit, OnDestroy {
    constructor(
        @Inject(MAT_DIALOG_DATA) private data: { assignor: Cedente; fileType: FileType; minibankId: number },
        private http: ApiService,
        private snackBar: MatSnackBar,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<any>,
        private userService: UserService,
        private uploadPayablesModalService: EnvioNotasModalService,
        private createOperationService: CreateOperationService,
    ) {
        this.fileType = data.fileType;
        this.assignor = data.assignor;
        this.minibankId = data.minibankId;
    }

    public nfeUploaded = new BehaviorSubject<boolean | null>(null);
    public files: File[] = [];
    public validFiles: File[] = [];
    public loading = false;
    public dropFileDisabled = false;
    public dataSource = [];
    private payables: Partial<Payable>[] = [];
    public inconsistencies: IOutputPayableError;
    private unsubscribeAll = new Subject();
    public fileType: FileType;
    private assignor: Cedente;
    private minibankId: number;
    public maxFileSize = MAX_FILE_SIZE;
    @Output() close = new EventEmitter();
    @Output() inconsistenciesEmitter = new EventEmitter<IOutputPayableError>();

    ngOnInit() {
        this.uploadPayablesModalService.getPayables$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((payables) => (this.payables = payables));

        this.uploadPayablesModalService.getUpdatePayables$.subscribe((id) => {
            this.addSelectedPayableID(false, { id });
        });

        this.minibankId = this.userService.user$.minibanco?.id;
    }

    addSelectedPayableID(event, singlePayable: Partial<Payable>) {
        if (event) {
            this.uploadPayablesModalService.setPayables = [...this.payables, singlePayable];
        }

        if (!event) {
            this.uploadPayablesModalService.setPayables = this.payables.filter(
                (payable) => payable.id !== singlePayable.id,
            );
        }
    }

    onRemove(event: globalThis.File) {
        this.files.splice(this.files.indexOf(event), 1);
        this.validFiles.splice(this.validFiles.indexOf(event), 1);
    }

    onSelect(event: NgxDropzoneChangeEvent) {
        const { addedFiles } = event;
        this.inconsistencies = null;

        if (this.fileType === FileType.XML) {
            this.files.push(...addedFiles);
            return;
        }

        this.files = addedFiles;
    }

    sendPayables() {
        if (!this.files.length) {
            this.snackBar.open(ErrorMessage.NO_FILE_SELECTED, "X", {
                duration: 5000,
            });
            return;
        }

        this.loading = true;
        this.dropFileDisabled = true;
        this.dialogRef.disableClose = true;

        if (this.fileType === FileType.XML) {
            this.uploadXML();
        }

        if (this.fileType === FileType.XLSX) {
            this.uploadXLSX();
        }
    }

    async uploadXML() {
        try {
            if (environment.environment !== "demo") {
                await this.validateXmlFiles();
            } else {
                this.validFiles = this.files;
            }

            if (this.validFiles) {
                const upload = await this.http
                    .makeFileRequest("crm", "/nfe/upload/xml", this.validFiles, "files", null, [
                        { minibancoId: this.userService.user$.minibanco.id, cedenteId: this.assignor.id },
                    ])
                    .toPromise();

                if ((upload as ICreatedPayablesXML)?.body?.recebiveisCreated?.length) {
                    const { recebiveisCreated: createdPayables } = (upload as ICreatedPayablesXML).body;

                    this.loading = false;
                    this.dropFileDisabled = false;
                    this.dialogRef.disableClose = false;
                    this.dialog.closeAll();

                    if (createdPayables.length) {
                        const partialPayableInfo: Partial<Payable>[] = createdPayables.map((payable: Payable) => {
                            const {
                                sacado: { razaoSocial, document },
                                valor,
                                dtVencimento,
                                id,
                            } = payable;
                            return {
                                id,
                                sacado: { razaoSocial, document },
                                valor,
                                dtVencimento: moment(dtVencimento).add(1, "days").format("DD/MM/YYYY"),
                            };
                        });

                        this.uploadPayablesModalService.setPayables = [...this.payables, ...partialPayableInfo];

                        this.snackBar.open(SuccessMessage.PAYABLES_CREATION_SUCCESS, "X", {
                            duration: 5000,
                            panelClass: "snack-bar-success",
                        });
                    }
                }
            }
        } catch {
            this.displayError(ErrorMessage.UNEXPECTED_ERROR);
        } finally {
            this.loading = false;
            this.dropFileDisabled = false;
            this.dialogRef.disableClose = false;

            if (this.inconsistencies) {
                this.inconsistenciesEmitter.emit(this.inconsistencies);
            }
        }
    }

    async validateXmlFiles() {
        const files = this.files;
        const notEmptyFiles = files.filter((file) => file.size > 0);

        for (const file of notEmptyFiles) {
            const result = await this.getXMLInconsistencies([file]);

            const fileFail = result?.errors?.details?.map((detail) => detail.file) || [];

            if (!fileFail.includes(file.name)) {
                this.validFiles.push(file);
            }
        }

        this.inconsistenciesEmitter.emit(this.inconsistencies);
    }

    async getXMLInconsistencies(file: File[]) {
        const inconsistencies = await this.createOperationService.getXMLInconsistencies(file);

        const [
            {
                assignor: { document, companyName, fantasyName },
            },
        ] = await this.uploadPayablesModalService.convertXML(file);

        if (document !== this.data.assignor.cnpj) {
            if (!this.inconsistencies?.errors) {
                this.inconsistencies = {
                    errors: {
                        title: "XML inválido",
                        status: 400,
                        details: [
                            {
                                file: file[0].name,
                                fileFailures: [`Cedente ${companyName || fantasyName}, difere do cedente da Operação`],
                                payablesFailures: [],
                            },
                        ],
                    },
                };

                this.displayError();

                return {
                    errors: {
                        ...this.inconsistencies.errors,
                        details: [...new Set(this.inconsistencies.errors.details)],
                    },
                };
            }

            if (this.inconsistencies?.errors?.details?.length) {
                this.inconsistencies.errors.details.push({
                    file: file[0].name,
                    fileFailures: [`Cedente ${companyName || fantasyName}, difere do cedente da Operação`],
                    payablesFailures: [],
                });

                this.displayError();

                return {
                    errors: {
                        ...this.inconsistencies.errors,
                        details: [...new Set(this.inconsistencies.errors.details)],
                    },
                };
            }
        }

        if (inconsistencies?.errors) {
            if (this.inconsistencies?.errors?.details?.length) {
                inconsistencies.errors.details.forEach((detail) => {
                    this.inconsistencies.errors.details.push(detail);
                });
            }

            if (!this.inconsistencies?.errors) {
                this.inconsistencies = inconsistencies;
            }

            this.displayError();

            return {
                errors: {
                    ...this.inconsistencies.errors,
                    details: [...new Set(this.inconsistencies.errors.details)],
                },
            };
        }
    }

    async uploadXLSX() {
        try {
            await this.validateSpreadsheet();
        } catch {
            this.loading = false;
            this.dropFileDisabled = false;
            this.dialogRef.disableClose = false;
        }
    }

    async validateSpreadsheet() {
        const file = this.files[0];
        await this.getXLSXInconsistencies(file);
    }

    async getXLSXInconsistencies(file: File) {
        try {
            const xlsxValidAndConverted = await this.createOperationService.getXLSXInconsistencies(file);

            if (xlsxValidAndConverted?.data?.length) {
                const convertedPayables = xlsxValidAndConverted?.data.map((payable: Partial<Payable>) => {
                    return {
                        ...payable,
                        assignorId: this.assignor.id,
                        minibankId: this.minibankId,
                    };
                });

                const payableData = await this.createOperationService.createPayablesFromXLSXFormatted({
                    data: convertedPayables,
                });

                const adjustedPayables = payableData.map((payable) => {
                    return {
                        ...payable,
                        dtVencimento: moment(payable.dtVencimento).add("days", 1).format("DD/MM/YYYY"),
                    };
                });

                this.uploadPayablesModalService.setPayables = [...this.payables, ...adjustedPayables];

                this.inconsistenciesEmitter.emit(null);
                this.dialog.closeAll();
                this.displaySuccess();
            }

            if (xlsxValidAndConverted?.error) {
                this.inconsistencies = {
                    errors: {
                        title: xlsxValidAndConverted.error.title,
                        status: Number(xlsxValidAndConverted.error.status),
                        details: [
                            {
                                file: file.name,
                                fileFailures: [""],
                                payablesFailures: xlsxValidAndConverted.error.details.payablesFailures,
                            },
                        ],
                    },
                };

                this.inconsistenciesEmitter.emit(this.inconsistencies);

                this.displayError();

                this.dialog.closeAll();
            }
        } catch {
            this.displayError(ErrorMessage.UNEXPECTED_ERROR);
        } finally {
            this.loading = false;
            this.dropFileDisabled = false;
            this.dialogRef.disableClose = false;
        }
    }

    displayError(message?: string) {
        this.snackBar.open(message ?? ErrorMessage.FILE_INCONSISTENCY_IMPORT_ERROR, "x", {
            duration: 5000,
            panelClass: ["snack-bar-error"],
        });
    }

    displaySuccess(message?: string) {
        this.snackBar.open(message ?? SuccessMessage.SUCCESSFULLY_IMPORTED_SECURITIES, "x", {
            duration: 5000,
            panelClass: ["snack-bar-success"],
        });
    }

    ngOnDestroy() {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }
}
