import { Injectable } from "@angular/core";
import { ApiService } from "app/core/api/api.service";
import { IOperationTypes } from "app/modules/admin/v2/operation/interfaces";
import { IOutputPayableError } from "../interfaces/output-payable-error.interface";
import { ICreatedPayable } from "../interfaces/created-payable.interface";
import { IXlsxValidationResponse } from "../interfaces/xlsx-validation-responseinterface";
import { ReceipientBankAccount } from "../types/receipiente-bank-account.interface";
import {
    UnoperatedPayablesResponse,
    UnoperatedPayablesSelection,
} from "./components/repurchase-payables-modal/interfaces/unoperated-payables";

@Injectable({
    providedIn: "root",
})
export class CreateOperationService {
    constructor(private http: ApiService) {}

    async getOperationTypes(): Promise<Array<IOperationTypes>> {
        const response = await this.http.get("crm", "/v2/operation/types").toPromise();

        return response.data;
    }

    async getXMLInconsistencies(files: File[]) {
        try {
            const { body } = await this.http.makeFileRequest("bff", "/payable/xml", files, "files").toPromise<any>();
            return body as IOutputPayableError;
        } catch {
            throw new Error("Ocorreu um erro ao comunicar com o servidor.");
        }
    }

    async getXLSXInconsistencies(file: File) {
        try {
            const { body } = await this.http.makeFileRequest("bff", "/payable/xlsx", [file], "file").toPromise<any>();

            return body;
        } catch {
            throw new Error("Ocorreu um erro ao comunicar com o servidor.");
        }
    }

    async createPayablesFromXLSXFormatted(data: IXlsxValidationResponse) {
        try {
            return this.http.post("crm", "/v2/payables/by-xlsx", data).toPromise<ICreatedPayable[]>();
        } catch {
            throw new Error("Ocorreu um erro ao comunicar com o servidor.");
        }
    }

    async getFundingAmount(): Promise<{
        amount: number;
        fundingLimit: number;
        fundingInterestRate: number;
        fundingId: string;
    } | null> {
        try {
            return this.http.get("oracle", "/funding/available").toPromise();
        } catch (error) {
            return Promise.resolve(null);
        }
    }

    async getAccountBalance(): Promise<number> {
        try {
            const { accountBalance } = await this.http.get("crm", "/dashboard/incomes").toPromise();

            return accountBalance;
        } catch {
            return null;
        }
    }

    async getAssignorBankAccounts(assignorId: number): Promise<ReceipientBankAccount[]> {
        try {
            return this.http.get("oracle", `/bff-front-bankme/assignors/${assignorId}/bank-accounts`).toPromise();
        } catch {
            return null;
        }
    }

    async getUnoperatedPayables(
        assignorId: number,
        { page, take }: { page?: number; take?: number },
    ): Promise<UnoperatedPayablesResponse> {
        try {
            const params = new URLSearchParams({
                page: String(page),
                take: String(take),
            }).toString();

            return this.http
                .get("oracle", `/bff-front-bankme/payable/unoperated/assignor/${assignorId}?${params}`)
                .toPromise();
        } catch {
            return null;
        }
    }

    async createRepurchasedPayables(
        data: UnoperatedPayablesSelection & { operationId: string },
    ): Promise<{ created: number; error: number }> {
        try {
            await this.http.post("oracle", "/bff-front-bankme/payable/repurchase", data).toPromise();
        } catch {
            return null;
        }
    }
}
